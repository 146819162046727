<template>
  <div class="order">
    <!-- <div class="row mb-3 align-items-end">
      <div class="col md-3">
        <h6 class="fs-2 fw-1 mb-2 color-light-green">{{ order.created_at | date }}</h6>
        <p class="fs-2 fw-3 color-light-green mb-0 pb-0">{{ 'Pedido #' + order.id }}</p>
      </div>
      <div class="col md-3">
        <h6 class="fs-2 fw-1 mb-2 color-light-green">Produtos</h6>
        <p class="fs-2 fw-3 color-light-green mb-0 pb-0">{{ totalProducts }}</p>
      </div>
      <div class="col md-3">
        <h6 class="fs-2 fw-1 mb-2 color-light-green">Valor</h6>
        <p class="fs-2 fw-3 color-light-green mb-0 pb-0">{{ order.amount | money }}</p>
      </div>
      <div class="col md-3 text-right" v-if="order.status == 2">
        <button class="btn btn-success btn-sm px-4" @click="changeStatus(3)">
          Aprovar
        </button>
      </div>
    </div> -->
    <div class="row mb-4">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <p class="fs-24 fw-600 c-black mb-0"> Pedido <span class="fs-18 fw-400 c-black-1 pl-4">#{{ order.id }}</span></p>
        <div class="d-flex align-items-center">
          <button class="btn btn-blue p-2 mr-2 px-md-4"  @click="openLogModal()">
            <svg width="18" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="#FFFFFF" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
            </svg>
            <p class="ml-2 d-none d-md-block mb-0">Log de Integrações</p>
          </button>

          <button class="btn btn-blue p-2 px-md-4"  @click="openLabelModal()">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53396 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16V8.42307V7.4V6.75425C16 6.29587 15.8425 5.8514 15.554 5.49524L11.7022 0.740983C11.3224 0.272273 10.7514 0 10.1482 0H9.625H8.53396ZM11.625 3.82283V5.4H12.9028L11.625 3.82283ZM4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H12C13.1046 18 14 17.1046 14 16V7.4H11.625C10.5204 7.4 9.625 6.50457 9.625 5.4V2H4ZM9 12.5L9.4 12.2C9.84183 11.8686 10.4686 11.9582 10.8 12.4C11.1314 12.8418 11.0418 13.4686 10.6 13.8L8.60808 15.2939C8.43959 15.4232 8.22876 15.5 8 15.5C7.77124 15.5 7.56041 15.4232 7.39192 15.2939L5.4 13.8C4.95817 13.4686 4.86863 12.8418 5.2 12.4C5.53137 11.9582 6.15817 11.8686 6.6 12.2L7 12.5V8.61139C7 8.05911 7.44772 7.61139 8 7.61139C8.55229 7.61139 9 8.05911 9 8.61139V12.5Z" fill="white"/>
            </svg>
            <p class="ml-2 d-none d-md-block mb-0">Gerar etiqueta</p>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-6">
        <div class="row">
          <div class="col-md-6">
            <CardPaymentOrder v-if="order.payments.length > 0" :order="order" :payments="order.payments" :cupom="order.cupom" :amount="order.amount" :tax="order.tax"/>
          </div>
          <div class="col-md-6 h-100">
            <CardShippingOrder v-if="order.shippings.length > 0" :shippings="order.shippings" :client="order"/>
          </div>
          <div class="col-12">
            <div class="info-products" v-for="product in order.products" :key="product.id">
                <CardProductOrderInfo :product="product"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 mt-3 mt-xl-0">
        <!-- Detalhes do cliente -->
        <div class="row">
          <div class="col-12 mt-3 mt-md-0 details">
            <div class="card py-4">
              <div class="row">
                <div class="col-12">
                  <h4>Detalhes do cliente</h4>
                </div>
                <div class="col-12 pb-3">
                  <p>Nome</p>
                  <h3>{{ order.name }}</h3>
                </div>
                <div class="col-md-4 pb-3">
                  <p>CPF/CNPJ</p>
                  <h5>{{order.tax_document_number}}</h5>
                </div>
                <div class="col-md-4 pb-3" v-if="order.user">
                  <p>Data de Nascimento</p>
                  <h5>{{order.user.details.birthdate}}</h5>
                </div>
                <div class="col-md-4 pb-3" v-if="order.user">
                  <p>Telefone</p>
                  <h5>{{'(' + order.user.details.phone_area_code + ')'}} {{order.user.details.phone_number}}</h5>
                </div>
                <div class="col-12">
                  <p>E-mail</p>
                  <h5>{{order.email}}</h5>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12 pb-3">
                  <p>Endereço</p>
                  <h5>{{order.street}}, {{order.street_number}} - {{ order.complement ? order.complement + ' - ' : '' }}{{order.district}}</h5>
                </div>
                <div class="col-md-4">
                  <p>Cidade - UF</p>
                  <h5>{{order.city}} - {{order.state}}</h5>
                </div>
                <div class="col-md">
                  <p>CEP</p>
                  <h5>{{order.zipcode}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <CardTaxInvoiceInfoOrder v-if="order.tax_invoices.length > 0" :invoices="order.tax_invoices"/>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <!-- <CardPaymentOrder v-if="order.payments.length > 0" :payments="order.payments" :cupom="order.cupom" :amount="order.amount"/> -->
        <!-- <CardShippingOrder v-if="order.shippings.length > 0" :shippings="order.shippings" :client="order"/> -->
        <!-- <CardTaxInvoiceInfoOrder v-if="order.tax_invoices.length > 0" :invoices="order.tax_invoices"/> -->
        <!-- <button type="button" class="btn btn-outline-success mt-2 w-100" @click="openLabelModal()">
          Gerar etiqueta
        </button> -->
      </div>
    </div>
    <div class="modal mt-5" id="logModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex align-items-center mb-3">
              <svg width="16" class="mr-2" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
              </svg>
              <p class="fs-16 fw-700 my-0"> Logs de Integrações </p>
            </div>
            <p class="mb-1" v-for="log in logs" :key="log.id">- {{ log.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="labelModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body text-white">
            <div class="row">
              <div class="col text-white">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div id="label_structure" class="px-5 py-5 bg-white">
              <div class="row">
                <div class="col-md-12">
                  <p class="py-0 my-0 text-dark">#{{ order.id }}</p>
                </div>
              </div>
              <hr class="border-dark">
              <div class="row">
                <div class="col">
                  <p class="py-0 my-0 text-dark">Data {{ order.created_at | datetime }}</p>
                </div>
              </div>
              <hr class="border-dark">
              <div class="row">
                <div class="col" v-if="order.shippings[0]">
                  <p class="py-0 my-0 text-dark">{{ order.shippings[0].service_code}} - {{ order.shippings[0].service_description }}</p>
                </div>
              </div>
              <hr class="border-dark">
              <div class="row">
                <div class="col">
                  <p class="py-0 my-0 text-dark">Nome: {{ order.name }}</p>
                  <p class="py-0 my-0 text-dark">Telefone: {{ order.phone }}</p>
                  <p class="py-0 my-0 text-dark" v-if="order.identity_document_number">RG: {{ order.identity_document_number }}</p>
                  <p class="py-0 my-0 text-dark">CPF: {{ order.tax_document_number }}</p>
                  <p class="py-0 my-0 text-dark">Email: {{ order.email }}</p>
                  <p class="py-0 my-0 text-dark">CEP: {{ order.zipcode }}</p>
                  <p class="py-0 my-0 text-dark">Endereço: {{ order.street + ', ' + order.street_number}} {{ order.complement ? order.complement : '' }} - {{ order.district + ' - ' +  order.city + ', ' + order.state }}</p>
                  <p class="py-0 my-0 text-dark">Método de pagamento solicitado: {{ order.payments[0] ? order.payments[0].param_payment_type_id : '' | payment_type }}</p>
                </div>
              </div>
              <hr class="border-dark">
              <div class="row">
                <div class="col">
                  <p class="py-0 my-0 text-dark">Custos</p>
                  <p class="py-0 my-0 text-dark">Preço dos produtos: {{ order.subtotal | money}}</p>
                  <p class="py-0 my-0 text-dark" v-if="order.shippings[0]">Preço de entrega: {{ order.shippings[0].amount | money }}</p>
                  <p class="py-0 my-0 text-dark">Descontos totais: {{ order.discount | money}}</p>
                  <p class="py-0 my-0 text-dark"> <b>Total a pagar: {{ order.amount | money}}</b> </p>
                </div>
              </div>
              <hr class="border-dark">
              <div class="row">
                <div class="col-12">
                  <p class="py-0 my-0 text-dark">Pedido</p>
                </div>
                <div v-for="details in order.products" :key="details.id" class="col-12">
                  <p class="py-0 my-0 text-dark">x{{ details.quantity }} {{ details.product.title }} {{ details.amount | money }}</p>
                  <p v-if="details.comments" class="form-control-label">{{ details.comments }}</p>
                  <p class="py-0 my-0 text-dark">Preço unitário {{ details.price | money }}</p>
                </div>
              </div>
              <hr class="border-dark">
              <div class="row">
                <div class="col">
                  <p v-if="order.shippings[0]" class="py-0 my-0 text-dark">Obs.: {{ order.shippings[0].comments }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-success" data-dismiss="modal" @click="createLabel()">Gerar PDF</button>
          </div>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !order.id || logLoading" />
  </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import CardProductOrderInfo from './components/CardProductOrderInfo.vue'
import CardPaymentOrder from './components/CardPaymentOrder.vue'
import CardShippingOrder from './components/CardShippingOrder.vue'
import CardTaxInvoiceInfoOrder from './components/CardTaxInvoiceInfoOrder.vue'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export default {

  name: 'ShowOrderPage',

  components: {
    LoadingComponent,
    CardProductOrderInfo,
    CardPaymentOrder,
    CardShippingOrder,
    CardTaxInvoiceInfoOrder
  },

  data () {
    return {
      order: {
        user: {
          details: {}
        },
        payments: [],
        shippings: [],
        tax_invoices: []
      },
      totalProducts: 0,
      client: {}
    }
  },

  watch: {
    details () {
      this.order = {
        ...this.details
      }
      this.totalProducts = this.order.products.length
    }
  },

  computed: mapState({
    details: state => state.orders.details,
    logs: state => state.logs.data,
    logLoading: state => state.logs.loading,
    loading: state => state.orders.loading
  }),

  methods: {

    ...mapActions('orders', [
      'show',
      'update'
    ]),

    ...mapActions('logs', [
      'companyOrderLogs'
    ]),

    changeStatus (status) {
      this.order.status = status
      this.update({ id: this.order.id, data: this.order })
    },

    openLabelModal: function () {
      $('#labelModal').modal('show')
    },

    openLogModal: function () {
      this.companyOrderLogs(this.order.id)
      $('#logModal').modal('show')
    },

    createLabel: async function () {
      var obj = new Date().toISOString()
      var date = obj.split('T')[0]

      const name = date + '_etiqueta.pdf'

      html2canvas(document.querySelector('#label_structure'), {
        allowTaint: true,
        useCORS: true,
        scale: 1
      }).then(function (canvas) {
        var doc = jsPDF()
        var img = canvas.toDataURL('image/png')
        var width = doc.internal.pageSize.getWidth()
        var height = doc.internal.pageSize.getHeight()

        doc.addImage(img, 'PNG', 0, 0, width, height)
        doc.save(name)
      })
    }

  },

  mounted () {
    this.show(this.$route.params.id)
  }
}

</script>

<style scoped>
  .white {
    color: white;
  }
</style>
