<template>
    <div class="card align-items-center p-4">
      <div class="image">
         <img :src="product_detail.image_thumbnail" height="100px" alt="Card image cap">
      </div>
      <div class="d-block mr-auto ml-3">
        <h4>{{ product.quantity }}un. - {{product.title}}</h4>
          <div class="align-items-center justify-content-between">
            <p>Preço Un.: {{ product.price | money }}</p>
            <p>Desconto: {{ product.discount | money }}</p>
            <p>Total: {{ product.amount - (product.discount * product.quantity) | money }}</p>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  props: ['product'],
  data () {
    return {
      product_detail: {}
    }
  },
  mounted () {
    this.product_detail = {
      ...this.product.product
    }
  }
}
</script>

<style scoped>
  .my-card {
      width: 180px;
  }
</style>
