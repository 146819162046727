<template>
  <div class="orders">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-4">
            <h1 class="h1-title"> Pedidos </h1>
          </div>
          <div class="col mt-2">
            <!-- <button class="btn btn-warning" v-if="download.length == 0" :disabled="load === true" @click="exportExcel()">
              {{ load === true ? 'Extraindo...' : 'Extrair Relatório' }}
            </button>
            <JsonExcel
              v-else
              class="btn btn-warning"
              :data="download"
              type="xls"
              worksheet="Download"
              name="relatório pedidos"
              data-dismiss="modal"
            /> -->
            <button class="btn btn-outline-warning d-block ml-auto" data-toggle="modal" @click="openExcelModal()">
              Extrair Relatório
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table style="width: 1700px" class="table table-striped table-bordered">
            <thead>
              <tr class="t-head">
                <th style="width:50px" scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    <small>#</small>
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th style="width:100px" scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    Nome
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th style="width:150px" scope="col">
                  <div class="row">
                    <div class="col-md-6 justify-content-start d-flex align-items-center">
                      <i class="fas fa-check mr-2"></i>
                      Tipo
                    </div>
                    <div class="col-md-6 justify-content-end d-flex align-items-center">
                      <div class="btn" @click="openFilterModal('status')">
                      <i class="fas fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="width:150px" scope="col">
                  <div class="row">
                    <div class="col-sm-9 justify-content-start d-flex align-items-center">
                      <i class="fas fa-coins mr-2"></i>
                      Pagamento
                    </div>
                    <div class="col-sm-3 justify-content-end d-flex align-items-center" >
                      <div class="btn" @click="openFilterModal('payment')">
                      <i class="fas fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="width:150px" scope="col">
                  <div class="row">
                    <div class="col-md-8 justify-content-start d-flex align-items-center">
                      <i class="fas fa-truck mr-2"></i>
                      Entrega
                    </div>
                    <div class="col-md-4 justify-content-end d-flex align-items-center">
                      <div class="btn" @click="openFilterModal('shipping')">
                      <i class="fas fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="width:100px" scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    E-mail
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th style="width:150px" scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    Forma de Pagamento
                    <div class="btn" @click="openFilterModal('payment_type')">
                      <i class="fas fa-filter"></i>
                    </div>
                  </div>
                </th>
                <th style="width:100px" scope="col" class="pb-3">
                  Valor Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders.data" :key="order.id">
                <td class="align-middle body-table p-0" v-if="haveTaxInvoice(order)">
                  <router-link :to="'./orders/' + order.id" class="btn btn-link c-black nowrap">
                    # {{ order.id}} | NF: {{order.tax_invoices[0].number}}
                  </router-link>
                </td>
                <td class="align-middle body-table p-0" v-if="!haveTaxInvoice(order)">
                  <router-link :to="'./orders/' + order.id" class="btn btn-link c-black nowrap">
                    # {{order.id}}
                  </router-link>
                </td>
                <td class="align-middle body-table p-0">
                  <router-link :to="'./orders/' + order.id" class="btn btn-link c-black nowrap">
                    {{ order.name.substr(0, 20)}}...
                  </router-link>
                </td>
                <td class="align-middle body-table"><OrderStatusComponent :order_status="order.status"/></td>
                <td class="align-middle body-table"><PaymentStatusComponent :payment_status="order.payments"/></td>
                <td class="align-middle body-table"><ShippingStatusComponent :shipping_status="order.shippings"/></td>
                <td class="align-middle body-table">{{ order.email }}</td>
                <td class="align-middle body-table">{{ order.payments[0] ? order.payments[0].param_payment_type_id : '' | payment_type}}</td>
                <td class="align-middle body-table">{{ order.amount | money }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginateComponent :source="pagination" @navigate="navigate"/>
      </div>
    </div>
    <LoadingComponent :loading="loading || !orders.data" />

    <!-- Modal de Pesquisa -->
    <div class="modal fade" tabindex="-1" id="searchModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Filtrar Listagem</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <input type="text" placeholder="Digite o que deseja filtrar" v-model="search" class="form-control my-4 mx-3">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-warning" @click="index({ page: 1, search, filter})" data-dismiss="modal">Filtrar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Filtro -->
    <div class="modal fade" tabindex="-1" id="filterModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Filtrar Listagem</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <!-- Select do status do pedido -->
            <select class="form-control" v-if="type == 'status'" v-model="filter.status">
              <option :value="null">Selecione o que deseja filtrar</option>
              <option :value="1">Rascunho</option>
              <option :value="2">Aguardando</option>
              <option :value="3">Autorizado</option>
              <option :value="4">Cancelado</option>
            </select>
            <!-- Select de Pagamento -->
            <select class="form-control" v-if="type == 'payment'" v-model="filter.payment">
              <option :value="null">Selecione o que deseja filtrar</option>
              <option :value="1">Pendente</option>
              <option :value="2">Autorizado</option>
              <option :value="3">Recusado</option>
              <option :value="4">Cancelado</option>
            </select>
            <!-- Select de Entrega -->
            <select class="form-control" v-if="type == 'shipping'" v-model="filter.shipping">
              <option :value="null">Selecione o que deseja filtrar</option>
              <option :value="1">Pendente</option>
              <option :value="2">Aguardando NF</option>
              <option :value="3">Aguardando Etiqueta</option>
              <option :value="4">Aguardando Envio</option>
              <option :value="5">A caminho</option>
              <option :value="6">Entregue</option>
              <option :value="7">Aguardando Devolução</option>
              <option :value="8">A caminho de Devolução</option>
              <option :value="9">Em Separação</option>
            </select>
            <!-- Select de Tipo de Pagamento -->
            <select class="form-control" v-if="type == 'payment_type'" v-model="filter.payment_type">
              <option :value="null">Selecione o que deseja filtrar</option>
              <option :value="1">Boleto</option>
              <option :value="2">Cartão de Crédito</option>
              <option :value="3">Pix</option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-warning" @click="index({ page: 1, search, filter})" data-dismiss="modal">Filtrar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- EXCEL MODAL -->
      <div class="modal fade mt-5" id="openExcelModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog " role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Selecione o intervalo de data</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body" >
                    <div class="row">
                      <div class="col">
                        <label for="from" class="form-control-label">De:</label>
                        <input type="date" id="from" @change="setOrderExcelParams" v-model="from" class="form-control">
                      </div>
                      <div class="col">
                        <label for="to" class="form-control-label">Até</label>
                        <input type="date" @change="setOrderExcelParams" id="to" v-model="to" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
                      <button class="btn btn-warning" v-if="download.length == 0" :disabled="load" @click="exportExcel()">Carregar Arquivos</button>
                      <JsonExcel
                        v-else
                        class="btn btn-warning"
                        :data="download"
                        type="xls"
                        worksheet="Download"
                        :name="'relatório pedidos '+ company_name"
                        data-dismiss="modal"
                      />
                  </div>
              </div>
          </div>
      </div>
    <!--  -->
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'
import OrderStatusComponent from './components/OrderStatusComponent.vue'
import PaymentStatusComponent from './components/PaymentStatusComponent.vue'
import ShippingStatusComponent from './components/ShippingStatusComponent.vue'
import JsonExcel from 'vue-json-excel'

export default {

  name: 'IndexOrderPage',

  components: {
    LoadingComponent,
    PaginateComponent,
    OrderStatusComponent,
    PaymentStatusComponent,
    ShippingStatusComponent,
    JsonExcel
  },

  computed: mapState({
    orders: state => state.orders.data,
    loading: state => state.orders.loading,
    pagination: state => state.orders.pagination,
    download: state => state.orders.download,
    company_name: state => state.orders.company_name
  }),

  data: function () {
    return {
      search: '',
      filter: {
        status: null,
        payment: null,
        shipping: null,
        payment_type: null
      },
      type: '',
      from: null,
      to: null,
      load: false
    }
  },

  methods: {

    ...mapActions('orders', [
      'index',
      'excel',
      'setOrderExcelParams'
    ]),

    haveTaxInvoice (order) {
      return (order.tax_invoices[0] !== undefined)
    },

    openFilterModal (type) {
      this.type = type
      $('#filterModal').modal('show')
    },

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search, filter: this.filter })
      $('#searchModal').modal('hide')
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    openExcelModal: function () {
      $('#openExcelModal').modal('show')
    },

    exportExcel: function () {
      this.load = true
      this.excel({ from: this.from, to: this.to })
      this.load = false
    }
  },

  mounted () {
    this.searchWithPaginate(1)
  }

}

</script>
