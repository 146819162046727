import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import LoginPage from '../views/auth/LoginPage.vue'

import IndexProductsPage from '../views/products/IndexProductsPage.vue'
import CreateProductsPage from '../views/products/CreateProductsPage.vue'
import ShowProductsPage from '../views/products/ShowProductsPage.vue'

import IndexCategoriesPage from '../views/categories/IndexCategoriesPage.vue'

import IndexCouponsPage from '../views/coupons/IndexCouponsPage.vue'
import ShowCouponsPage from '../views/coupons/ShowCouponsPage.vue'

import IndexIntegrationsPage from '../views/integrations/IndexIntegrationsPage.vue'

import IndexOrderPage from '../views/orders/IndexOrderPage.vue'
import ShowOrderPage from '../views/orders/ShowOrderPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Login',
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Home',
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Produtos',
    component: IndexProductsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Produtos',
      requiresAuth: true
    }
  },
  {
    path: '/products/create',
    name: 'Adicionar Produto',
    component: CreateProductsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Adicionar Produto',
      requiresAuth: true
    }
  },
  {
    path: '/products/:id',
    name: 'Editar Produto',
    component: ShowProductsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Editar Produto',
      requiresAuth: true
    }
  },
  {
    path: '/coupons',
    name: 'Cupons',
    component: IndexCouponsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Cupons',
      requiresAuth: true
    }
  },
  {
    path: '/coupons/:id',
    name: 'Editar Cupom',
    component: ShowCouponsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Editar Cupom',
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: IndexCategoriesPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Categorias',
      requiresAuth: true
    }
  },
  {
    path: '/integrations',
    name: 'Integrações',
    component: IndexIntegrationsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Integrações',
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Pedidos',
    component: IndexOrderPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Pedidos',
      requiresAuth: true
    }
  },
  {
    path: '/orders/:id',
    name: 'Detalhes do Pedido',
    component: ShowOrderPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Pedido',
      requiresAuth: true
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.token) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
