import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth/auth'
import { categories } from './categories/categories'
import { coupons } from './coupons/coupons'
import { integrations } from './integrations/integrations'
import { orders } from './orders/orders'
import { products } from './products/products'
import { reports } from './reports/reports'
import { params } from './params/params'
import { preferences } from './preferences/preferences'
import { shippings } from './shippings/shippings'
import { logs } from './logs/logs'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    categories,
    coupons,
    integrations,
    orders,
    products,
    reports,
    params,
    preferences,
    shippings,
    logs
  }
})
